.carouselTitle h1,
.carouselTitle h2,
.carouselTitle h3 {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .carouselTitle {
    position: absolute;
    top: 15%;
    left: 50px;
    text-align: center;
  }

  .carousel {
    height: auto;
    margin-top: 1em;

  }

  .carouselTitle {
    text-align: left;
  }

  .carouselTitle h1 {
    line-height: 1em;
    font-size: 1.5em;
    letter-spacing: 7px;
  }

  .carouselTitle h2 {
    line-height: 1em;
    font-size: 1em;
    letter-spacing: 5px;
  }

  .carouselTitle h3 {
    line-height: 1em;
    font-size: 0.6em;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 768px) {
  .carouselTitle {
    position: absolute;
    top: 25%;
    left: 100px;
    text-align: center;
  }

  .carousel {
    height: 70vh;
    margin-top: 3em;
  }

  .carouselTitle {
    text-align: center;
  }

  .carouselTitle h1 {
    line-height: 1em;
    font-size: 2.5em;
    letter-spacing: 7px;
  }

  .carouselTitle h2 {
    line-height: 1em;
    letter-spacing: 5px;
  }

  .carouselTitle h3 {
    line-height: 1em;
    font-size: 0.8em;
    letter-spacing: 2px;
  }
}
