.saw-container {
    text-align: center;
    padding: 50px;
    font-family: 'Arial', sans-serif;
  }
  
  .saw-animation {
    position: relative;
    width: 96px;
    height: 100px;
    margin: 50px auto;
  }
  
  .wood {
    width: 109px;
    height: 35px;
    background-color: #8b4513;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .saw {
    position: absolute;
    top: -40px;
    left: -50px;
    transform: rotate(45deg);
    animation: saw-move 2s infinite cubic-bezier(0.8, 0, 0.2, 1);
  }
  
  .saw-blade {
    width: 100px;
    height: 80px;
  }

  
.saw-handle {
    position: absolute;
    width: 17px; /* Largeur du manche */
    height: 63px; /* Hauteur du manche */
    background-color: #5C4033; /* Marron foncé */
    top: 0px; /* Position verticale pour coller à la lame */
    left: 163px; /* Alignement avec la lame */
    transform: rotate(90deg); /* Rotation de 90 degrés */
    transform-origin: top left; /* Origine de la transformation */
  }
  
  @keyframes saw-move {
    0% {
        left: -20px; /* Réduit le décalage initial */
        top: -20px;  /* Réduit le décalage initial */
    }
    50% {
        left: 50px;  /* Réduit le décalage à la moitié de l'animation */
        top: 30px;   /* Réduit le décalage à la moitié de l'animation */
    }
    100% {
        left: -20px; /* Réduit le retour à la position initiale */
        top: -20px;  /* Réduit le retour à la position initiale */
    }
}

  