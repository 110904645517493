.main-text {
    cursor: pointer;
    margin-top: 0.29em;
  }
  
  .extra-texts {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease;
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;
  }
  
  .extra-texts.open {
    max-height: 200px; /* Ajustez selon la taille de votre contenu */
  }
  
  .extra-text {
    text-align: left;
    margin-top: 0.8em;
    margin-left: 0.8em;
    margin-right: 0.5em;
  }
  