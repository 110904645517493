.pageVerteContent{
    font-family: "Montserrat", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 2em;
    
    @media (max-width:768px) {
        img {
            width: 100%;
        }
    }
        @media (max-width:768px) {
        img {
            width: 100%;
        }
    }
}

.pageVerteContent table{
    margin-bottom: 2em;
}