.tilesMetiers {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: white;
}

@media (max-width: 767.98px) {
  .flexRowMenuWelcome {
    display: flex;
    width: 100vw;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
    margin-top: 2em;
    font-size: 0.7em;
    gap: 1em;
  }

  .tilesMetiers {
    display: flex;
    flex-direction: row;
    gap: 2em;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2em;
    margin-left: 1%;
    margin-right: 1%;
  }
}

@media (min-width: 767.98px) {
  .flexRowMenuWelcome {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6em;
    margin-top: 2em;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-left: 15%;
    margin-bottom: 3em;
    margin-right: 15%;
  }

  .tilesMetiers {
    display: flex;
    flex-direction: row;
    gap: 2em;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2em;
    margin-left: 15%;
    margin-right: 15%;
  }
}
