.mainPageResumeContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
}

.titleElement {
  font-family: "Montserrat", sans-serif;
  color: black;
  background-color: #f8f7f6;
  font-weight: 300;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  position: relative;
}

.mainContent p, a {
  font-family: "Montserrat", sans-serif;
  line-height: 1.8em;
  text-align: justify;
  color: black;
}

.mainContent {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .titleElement {
    font-size: 1em;
    bottom: -7em;
    text-align: center;
  }

  .firstRowOfTiles {
    gap: 1em;
  }

  .flexColumnForPageResume {
    width: 100%;
  }

  .bottomImage {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .titleElement {
    font-size: 1.5em;
    bottom: -5em;
  }
  .firstRowOfTiles {
    gap: 3em;
  }
  .flexColumnForPageResume {
    width: 50%;
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media only screen and (min-width: 1400px) {
  .titleElement {
    font-size: 1.5em;
    bottom: -5em;
  }
  .firstRowOfTiles {
    gap: 8em;
    max-width: 70%;
  }

  .flexColumnForPageResume {
    width: 50%;
    margin-left: 3%;
    margin-right: 3%;
  }
}

.firstRowOfTiles {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flexColumnForPageResume {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rowForSecondTiles {
  display: flex;
  flex-direction: row;
  gap: 2em;
}
