.linkWelcomePage {
  text-decoration-line: none;
  color: inherit;
  width: 100%;
  height: 100%;
  align-content: center;
  text-align:center;
}

@media (max-width: 767.98px) {
  .linkWelcomePage {
    font-size: 0.8em;
  }

  .tileContainer{
    height: 150px;
  }
}

@media (min-width: 767.98px) {
  .linkWelcomePage {
    font-size: 1.1em;
  }

  .tileContainer{
    height: 300px;
  }
}
