.menu {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: -250px;
  transition: left 0.3s ease-in-out;
  background-color: #F0E1D1;
  z-index: 23;
}

.mainMenu{
  height: 100vh;
  overflow-y: auto;
}

.hoverCursor{
  cursor: pointer;
}

.menu-container {
  color: black;
}

.menu-button {
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 999;
  display: flex;
}

.bar {
  width: 2.5em;
  height: 0.2em;
  background-color: black;
  transition: 0.3s;
}

.burger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.5em;
}

.burger-icon.open .bar1 {
  transform: rotate(-45deg) translate(0, 0.9em);
}

.burger-icon.open .bar2 {
  opacity: 0;
  transition: opacity 0.1s ease;
}

.burger-icon.open .bar3 {
  transform: rotate(45deg) translate(0, -0.9em);
}

.menuName {
  margin-left: 0.8em;
  font-size: 1.2em;
  font-family: "Montserrat";
  font-display: swap;
}

.menu.open {
  left: 0;
}

.menu .mainMenu {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 1.5em;
  margin-top: 4em;
}

.mainMenu li {
  padding: 1em;
  transition: background-color 0.3s;
  font-family: "Montserrat", sans-serif;
  font-display: swap;
  font-weight: 500;
}

.subMenu li {
  padding: 0.7em;
}

.menuLink {
  text-decoration: none;
  list-style: none;
  color: inherit;
}

.subMenuLink {
  text-decoration: none;
  list-style: none;
  color: inherit;
}

.subMenu {
  transition: all 0.3s ease;
}

.menuLink:hover {
  color: #0e2d20;
}

.subMenuLink:hover {
  color: #ab6d43;
}
