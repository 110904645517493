.tileDetailImage {
  width: 100%;
  /* height: 100%; */
}

.linkPageDetail {
  text-align: center;
  background-color: #f6f3f0;
  font-family: "Montserrat", sans-serif;
  text-decoration-line: none;
  color: inherit;
  width: 100%;
  height: 100%;
  align-content: center;
  font-size: 1.1em;
}

.tileDetailTitle {
  color: #986c4a;
  margin-top: 1em;
}

@media only screen and (max-width: 768px) {
  .tileDetailTitle {
    font-size: 0.5em;
  }

  .tilePageDetailContainer {
    max-width: 84px;
  }
}

@media only screen and (min-width: 768px) {
  .tileDetailTitle {
    font-size: 0.8em;
  }

  .tilePageDetailContainer {
    max-width: 200px;
  }
}
