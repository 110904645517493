.particle-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Ajustez la hauteur comme nécessaire */
    overflow: hidden;
    top:56px;
    left: 50px;
  }
  
  .particle {
    position: absolute;
    bottom: 100%; /* Commence en dehors de l'écran */
    width: 3px; /* Largeur des particules */
    height: 3px; /* Hauteur des particules */
    border-radius: 50%; /* Pour rendre les particules rondes */
  }
  
  @keyframes fall {
    to {
      bottom: 0; /* La particule tombe jusqu'en bas */
      opacity: 0; /* La particule disparaît */
    }
  }
  