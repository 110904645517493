.header {
  justify-content: center;
}

.quartDeCercle {
  width: 80px;
  height: 80px;
  background-color: rgb(255, 255, 255);
  border-bottom-left-radius: 100%;
  position: fixed;
  right: 0px;
  top: 0px;
  overflow: hidden;
  z-index: 5;
}

@media only screen and (max-width: 768px) {
  /* Ajoutez vos styles spécifiques pour les écrans de taille moyenne ici */
  .logoHeader {
    width: 60%;
  }

  header {
    margin-top: 2em;
  }
}

@media only screen and (min-width: 768px) {
  /* Ajoutez vos styles spécifiques pour les grands écrans ici */
  .logoHeader {
    width: 20%;
  }

  header {
    margin-top: 2em;
  }
}

.phoneLogo {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 5;
  height: 2em;
  width: 2em;
  background-size: cover;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap");

.mainHeader {
  text-align: center;
}

.flexRowMenuWelcome a {
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease-in-out;
  text-align: center;
}

.flexRowMenuWelcome a:hover {
  transform: scale(1.05);
}

.flexMenuItem {
  flex: 1;
}
