/* CookieConsentBanner.css */
.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(134, 134, 134, 0.9);
    color: rgb(255, 255, 255);
    padding: 20px;
    text-align: center;
    z-index: 1000;
  }
  
  
  .cookies-button-container{
    display: flex;
  }
  
  .cookie-consent-banner h3 {
    margin: 0 0 10px;
  }
  
  .cookie-consent-banner p {
    margin: 0 0 20px;
  }
  
  .cookie-consent-button {
    margin: 0 10px;
    padding: 10px 20px;
    color: rgb(0, 0, 0);
    border: none;
    cursor: pointer;
  }
  
  .btn-success {
    background: white;
  }
  
  .btn-outline {
    background: rgb(0, 0, 0);
  }
  
  .btn-grayscale {
    background: white;
  }
  
  .cookie-consent-options {
    margin-top: 20px;
  }
  
  .cookie-consent-options label {
    display: block;
    margin: 5px 0;
  }
  
  #cookie-consent-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
  }
  